import React, { useState, useMemo, useCallback, useEffect } from "react";
import { remove } from "lodash";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import classnames from "classnames";
import Pagination from "components/common/Pagination";
import ReactTableWrapper from "components/reacttable/reacttbl.style";
//import { Button } from "reactstrap";
//import SweetAlert from "react-bootstrap-sweetalert";
import { getProductByInternalCode } from "services/ProductsService";
import { connect } from "react-redux";
import FilterComponent from "components/reacttable/subcomponents/FilterComponent";
import HeaderComponent from "components/reacttable/subcomponents/HeaderComponent";
import DropdownFilterComponent from "components/reacttable/subcomponents/DropdownFilterComponent";
import { Badge, Button } from "reactstrap";

const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    }
  };
};

const badgeStyle = value => {
  switch (true) {
    case value < process.env.REACT_APP_LOW_INVENTORY_RED:
      return "c-danger";
    case value < process.env.REACT_APP_LOW_INVENTORY_YELLOW:
      return "c-warning";
    default:
      return "c-info";
  }
};

const ExistenciasTable = ({ data, authData }) => {
  const [entradasData, setEntradasData] = useState(data);
  const [productData, setProductData] = useState({});
  const [modal, setModal] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);

  useEffect(() => {
    setEntradasData(data);
  }, [data]);

  const deleteClick = useCallback(
    data => {
      // Here you can view the data and delete through API calling
      const array = entradasData;
      remove(array, function(n) {
        return n.id === data.id;
      });
      setEntradasData([...array]);
    },
    [entradasData]
  );

  const viewClick = useCallback(
    data => {
      getProductByInternalCode(
        setProductData,
        authData.token,
        data.item.internal_code
      );
    },
    [productData]
  );

  const editClick = data => {
    // Here you can view the data and make forward action for edit data
    alert(JSON.stringify(data));
  };

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Código Interno"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "Código Interno",
        accessor: "item.internal_code"
        //Cell: props => <div> {toCurrency(props.value)} </div>
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Descripción"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "Descripción del producto",
        accessor: "item.description"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Marca"
            />
          );
        },
        Filter: DropdownFilterComponent,
        placeholder: "Marca del producto",
        accessor: "item.brand"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Familia"
            />
          );
        },
        Filter: DropdownFilterComponent,
        placeholder: "Familia",
        accessor: "item.category"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Almacén"
            />
          );
        },
        Filter: DropdownFilterComponent,
        filter: "equals",
        placeholder: "Dónde se encuentra",
        accessor: "store"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Cantidad"
            />
          );
        },
        Filter: DropdownFilterComponent,
        placeholder: "Cantidad",
        accessor: "quantity",
        Cell: props => (
          <div>
            {" "}
            {
              <Badge className={`${badgeStyle(props.value)} pa-10`}>
                {props.value}
              </Badge>
            }{" "}
          </div>
        )
      }
      /*{
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Acción"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class wide-cell">
              <button
                className="react-table-view-button"
                onClick={() => viewClick(tableInstance.row.original)}
              >
                <i className="fas fa-eye" />
              </button>
              <button
                className="react-table-edit-button"
                onClick={() => editClick(tableInstance.row.original)}
              >
                <i className="fas fa-edit" />
              </button>
              <button
                className="react-table-delete-button"
                onClick={() => deleteClick(tableInstance.row.original)}
              >
                <i className="fas fa-trash" />
              </button>
            </div>
          );
        }
      }*/
    ],
    [deleteClick, viewClick]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setAllFilters,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex, filters }
  } = useTable(
    {
      data: entradasData,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <ReactTableWrapper {...data}>
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1">
            <span className="hash"># </span> Existencias
          </div>
          {filters && filters.length > 0 && (
            <div className="mr-10">
              <Button
                className="c-btn ma-5 c-info"
                onClick={() => setAllFilters([])}
              >
                <i className="fas fa-filter mr-10" />
                Quitar filtros
              </Button>
            </div>
          )}
        </div>
        <div className="table-container text-center overflow-auto">
          <table
            border={1}
            className="custom-react-table-theme-class"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => (
                    <th
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                    >
                      <div>{header.render("Header")}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => {
                    return (
                      <td
                        {...header.getHeaderProps(
                          header.getSortByToggleProps()
                        )}
                      >
                        <div>
                          {header.canFilter ? header.render("Filter") : null}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          onPageChange={gotoPage}
          pages={pageCount}
          page={pageIndex}
        />
      </div>
    </ReactTableWrapper>
  );
};

export default connect(authData)(ExistenciasTable);
