import PageTitle from "components/common/PageTitle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import modalDialogsActions from "redux/modalDialogs/actions";
import UsuariosListaTable from "./UsuariosListaTable";
import {
  getActiveUsersList,
  getInactiveUsersList,
  updateUserById,
  createUser,
  setUserAsInactiveById,
  setUserAsActiveById
} from "services/UsersService";
import EmtecLoader from "components/common/EmtecLoader";

const { onError, onSuccess } = modalDialogsActions;

const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    }
  };
};

const UsuariosLista = ({ authData, onError, onSuccess }) => {
  const [usuarios, setUsuarios] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isTrashcan, setIsTrashcan] = useState(false);

  useEffect(() => {
    if (!isTrashcan) {
      getActiveUsersList(setUsuarios, authData.token)
        .then(() => {
          setRefresh(false);
        })
        .catch(err => onError(err));
    } else {
      getInactiveUsersList(setUsuarios, authData.token)
        .then(() => {
          setRefresh(false);
        })
        .catch(err => onError(err));
    }
  }, [onError, authData.token, refresh, isTrashcan]);

  const toggleTrashcan = () => {
    setIsTrashcan(isTrashcan => !isTrashcan);
    console.log("Cambió isTrashcan");
    console.log("isTrashcan", isTrashcan);
  };

  const handleUpdate = async updatedUser => {
    const payload = {
      user: updatedUser
    };
    if (payload.user.id) {
      await updateUserById(() => null, authData.token, payload)
        .then(() =>
          onSuccess({
            message: "Los datos del usuario se actualizaron exitosamente"
          })
        )
        .catch(onError);
    } else {
      await createUser(() => null, authData.token, payload)
        .then(() =>
          onSuccess({
            message: "Se creó el nuevo usuario exitosamente"
          })
        )
        .catch(onError);
    }
    setRefresh(true);
  };

  const handleInactive = async data => {
    if (data.active) {
      await setUserAsInactiveById(() => null, authData.token, data.id)
        .then(() =>
          onSuccess({
            message: `Se eliminó el usuario ${data.full_name}.`
          })
        )
        .catch(onError);
    } else {
      await setUserAsActiveById(() => null, authData.token, data.id)
        .then(() =>
          onSuccess({
            message: `Se restauró el usuario ${data.full_name}.`
          })
        )
        .catch(onError);
    }
  };

  return (
    <div>
      <PageTitle
        title="topbar.usuarios.lista"
        className="plr-15"
        breadCrumb={[
          {
            name: "topbar.usuarios"
          },
          {
            name: "topbar.usuarios.lista"
          }
        ]}
      />

      <EmtecLoader visible={usuarios?.length === 0} />

      {usuarios?.length > 0 && (
        <UsuariosListaTable
          data={usuarios}
          handleUpdate={handleUpdate}
          handleInactive={handleInactive}
          toggleTrashcan={toggleTrashcan}
          isTrashcan={isTrashcan}
        />
      )}
    </div>
  );
};

export default connect(authData, { onError, onSuccess })(UsuariosLista);
