import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    email: Yup.string().required("Pon tu nombre de usuario"),
    password: Yup.string().required("Pon tu Password")
  }),
  mapPropsToValues: props => ({
    email: "admin",
    password: "password"
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
