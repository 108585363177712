import axios from "axios";
import { baseUrl } from "helper/constant";

const buildHeaders = token => {
  return {
    headers: {
      Authorization: token
    }
  };
};

const getRolesList = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/roles`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

const getUsersList = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/users`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data.data);
};

const getActiveUsersList = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/users`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  const filteredData = response.data.data.filter(user => user.active === true);
  state(filteredData);
};

const getInactiveUsersList = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/users`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  const filteredData = response.data.data.filter(data => data.active === false);
  state(filteredData);
};

const getUserById = async (state, token, id) => {
  const response = await axios
    .get(`${baseUrl}/users/${id}`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

const getCurrentUser = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/current_user`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

const updateUserById = async (state, token, body) => {
  const response = await axios
    .put(`${baseUrl}/users/${body.user.id}`, body, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

const setUserAsInactiveById = async (state, token, id) => {
  const response = await axios
    .delete(`${baseUrl}/users/${id}/discard`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

const setUserAsActiveById = async (state, token, id) => {
  const response = await axios
    .patch(`${baseUrl}/users/${id}/undiscard`, null, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

const createUser = async (state, token, body) => {
  const response = await axios
    .post(`${baseUrl}/signup/`, body, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

export {
  getRolesList,
  getUsersList,
  getActiveUsersList,
  getInactiveUsersList,
  getUserById,
  getCurrentUser,
  updateUserById,
  setUserAsInactiveById,
  setUserAsActiveById,
  createUser
};
