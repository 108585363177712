const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",

  login: data => {
    return {
      type: authActions.LOGIN,
      isLogin: true,
      accessToken: data.token,
      currentUser: data.currentUser
    };
  },
  logout: () => {
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null,
      currentUser: null
    };
  }
};

export default authActions;
