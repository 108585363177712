import axios from "axios";
import { baseUrl } from "helper/constant";

const buildHeaders = token => {
  return {
    headers: {
      Authorization: token
    }
  };
};

const getClients = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/fiscal_entities/clients`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data.data.clients);
};
const getSuppliers = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/fiscal_entities/suppliers`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data.data.suppliers);
};
const getFiscalEntities = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/fiscal_entities/all`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data.data.fiscal_entities);
};
const updateFiscalEntityById = async (state, token, body, id) => {
  const response = await axios
    .put(`${baseUrl}/fiscal_entities/${id}/update`, body, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};
const createFiscalEntity = async (state, token, body) => {
  const response = await axios
    .post(`${baseUrl}/fiscal_entities/create`, body, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

export {
  getClients,
  getSuppliers,
  getFiscalEntities,
  updateFiscalEntityById,
  createFiscalEntity
};
