import React, { useState } from "react";
import { connect } from "react-redux";
import modalDialogsActions from "redux/modalDialogs/actions";
import { getAcceptImportedInvoice } from "services/InventarioService";

const { onError, onSuccess, clearMessage } = modalDialogsActions;

const EntradasPreImportResume = ({
  invoiceId,
  token,
  hasNoErrors,
  onError,
  onSuccess,
  handleSuccess
}) => {
  //422 not accepted
  //200 accepted

  const [acceptedResponse, setAcceptedResponse] = useState({});

  const handleSubmit = async () => {
    await getAcceptImportedInvoice(setAcceptedResponse, token, invoiceId)
      .then(() => {
        onSuccess({
          message: "El inventario se actualizó exitosamente"
        });
        handleSuccess();
      })
      .catch(onError);
  };

  return (
    <>
      {hasNoErrors && (
        <div className="roe-card-style">
          <div className="roe-card-header c-success mb-4">
            Factura {invoiceId} lista para importarse
          </div>
          <div className="roe-card-body">
            <button
              className="c-btn ma-5 c-primary"
              type="submit"
              onClick={handleSubmit}
            >
              <i className="fas fa-boxes"></i> Guardar en Inventario
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(null, { onSuccess, onError, clearMessage })(
  EntradasPreImportResume
);
