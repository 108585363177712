import React from "react";
import { ThreeCircles } from "react-loader-spinner";

const EmtecLoader = ({ visible }) => {
  return (
    <ThreeCircles
      visible={visible}
      height="100"
      width="100"
      color="#ffc107"
      ariaLabel="Cargando..."
      wrapperStyle={{}}
      wrapperClass="emtec-loader"
    />
  );
};

export default EmtecLoader;
