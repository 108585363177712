import React, { useEffect, useState } from "react";
import { postInvoiceFixJson } from "services/InventarioService";

const EntradasPreImportTableErrors = ({
  items,
  invoiceId,
  token,
  moveToOk,
  handleHasErrors
}) => {
  const [products, setProducts] = useState(items);
  const [payload, setPayload] = useState(null);
  const [fixJsonResponse, setFixJsonResponse] = useState(null);

  const handleDescriptionChange = (index, newDescription) => {
    setProducts(prevProducts => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index].invoice_concept.description = newDescription;
      return updatedProducts;
    });
    setPayload({
      invoice: {
        concepts: products.map(product => product.invoice_concept)
      }
    });
  };

  useEffect(() => {
    handleHasErrors(products.length > 0);
    if (fixJsonResponse) {
      moveToOk(fixJsonResponse.data.items_ready_to_store);
      setProducts(fixJsonResponse.data.errors);
      handleHasErrors(fixJsonResponse.data.errors.length > 0);
    }
  }, [fixJsonResponse]);

  const handleSubmit = event => {
    event.preventDefault();
    postInvoiceFixJson(setFixJsonResponse, token, payload, invoiceId);
  };

  return (
    <>
      {Object.keys(products).length > 0 && (
        <div className="roe-card-style">
          <div className="roe-card-header c-danger mb-4">
            {Object.keys(products).length} ítems con errores
          </div>
          <div className="roe-card-body">
            <form onSubmit={handleSubmit}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Producto</th>
                    <th scope="col">Cant.</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          type="text"
                          onChange={e =>
                            handleDescriptionChange(index, e.target.value)
                          }
                          value={item.invoice_concept.description}
                        />
                        <br />
                        <small className="text-danger">{item.reason}</small>
                      </td>
                      <td>{item.invoice_concept.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className="c-btn ma-5 c-primary" type="submit">
                <i className="fas fa-edit"></i> Guardar correcciones
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default EntradasPreImportTableErrors;
