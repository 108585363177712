import Intro from "views/Intro";
import descuentos from "views/modules/datosmaestros/descuentos";
import productos from "views/modules/datosmaestros/productos/Productos";
import almacenes from "views/modules/inventario/almacenes";
import cartaporteemitidas from "views/modules/inventario/cartaporteemitidas";
import crearreportesurtimiento from "views/modules/inventario/crearreportesurtimiento";
import entradas from "views/modules/inventario/entradas/entradas";
import existencias from "views/modules/inventario/existencias/existencias";
import lotes from "views/modules/inventario/lotes";
import reportedeinventariolth from "views/modules/inventario/reportedeinventariolth";
import reportes from "views/modules/inventario/reportes";
import salidas from "views/modules/inventario/salidas";
import traspasos from "views/modules/inventario/traspasos";
import UsuariosLista from "views/modules/usuarios/UsuariosLista";
import Entities from "views/modules/datosmaestros/entities/Entities";

const dashboardRoutes = [
  { path: "/intro", component: Intro },

  { path: "/usuarios/lista", component: UsuariosLista },

  { path: "/datosmaestros/entidades", component: Entities },
  { path: "/datosmaestros/productos", component: productos },
  { path: "/datosmaestros/descuentos", component: descuentos },

  { path: "/inventario/almacenes", component: almacenes },
  { path: "/inventario/cartaporteemitidas", component: cartaporteemitidas },
  {
    path: "/inventario/crearreportesurtimiento",
    component: crearreportesurtimiento
  },
  { path: "/inventario/existencias", component: existencias },
  { path: "/inventario/entradas", component: entradas },
  { path: "/inventario/lotes", component: lotes },
  {
    path: "/inventario/reportedeinventariolth",
    component: reportedeinventariolth
  },
  { path: "/inventario/reportes", component: reportes },
  { path: "/inventario/salidas", component: salidas },
  { path: "/inventario/traspasos", component: traspasos }
];

export default dashboardRoutes;
