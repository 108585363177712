import { xml2json } from "xml-js";
import { FileUploader } from "react-drag-drop-files";
import React, { useEffect, useState } from "react";
import xml2js from "xml2js";
import { postInvoicesLoadJson } from "services/InventarioService";
import EntradasPreImportTableOk from "./entradasPreImportTable";
import EntradasPreImportTableErrors from "./entradasPreImportTableErrors";
import { connect } from "react-redux";
import modalDialogsActions from "redux/modalDialogs/actions";
import EntradasPreImport from "./entradasPreImport";
import EntradasPreImportResume from "./entradasPreImportResume";

const fileTypes = ["XML"];
const { onError, onSuccess } = modalDialogsActions;
const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    }
  };
};

const EntradasImport = ({ handleReloadData, authData }) => {
  const [file, setFile] = useState(null);
  const [xml, setXml] = useState(null);
  const [json, setJson] = useState(null);
  const [importResult, setImportResult] = useState(null);
  const [hasErrors, setHasErrors] = useState(false);

  const handleChange = file => {
    setImportResult(null);
    setFile(file);
  };

  const handleImport = payload => {
    if (payload.elements) {
      postInvoicesLoadJson(setImportResult, authData.token, payload);
      setFile(null);
    }
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = evt => {
        const readerData = evt.target.result;
        setXml(readerData);
        setJson(JSON.parse(xml2json(xml, { spaces: 2 })));
      };
    }
  }, [file, json]);

  const moveToOk = items => {
    const newResult = {
      ...importResult,
      data: {
        ...importResult.data,
        items_ready_to_store: [
          ...importResult.data.items_ready_to_store,
          ...items
        ]
      }
    };
    setImportResult(newResult);
  };

  const handleHasErrors = hasErrors => {
    setHasErrors(hasErrors);
  };

  const handleSuccess = () => {
    setFile(null);
    setJson(null);
    setXml(null);
    setImportResult(null);
    setHasErrors(false);
  };

  return (
    <>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        label="Arrastre y suelte su CFDI en XML aquí"
        hoverTitle="Suelte aquí"
      />
      {file && xml && json && json?.elements && (
        <div>
          <EntradasPreImport data={json} />
          <button
            className="c-btn ma-5 c-primary"
            onClick={() => {
              handleImport(json);
            }}
          >
            <i className="fas fa-file-import"></i> Importar CFDI
          </button>
        </div>
      )}
      {importResult && (
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header">Resultado</div>
          <div className="roe-card-body container">
            <div className="row">
              <div className="col">
                <EntradasPreImportTableOk
                  items={importResult.data.items_ready_to_store}
                />
              </div>
              <div className="col">
                <EntradasPreImportTableErrors
                  items={importResult.data.errors}
                  invoiceId={importResult.data.invoice.id}
                  token={authData.token}
                  moveToOk={moveToOk}
                  handleHasErrors={handleHasErrors}
                />
                <EntradasPreImportResume
                  invoiceId={importResult.data.invoice.id}
                  token={authData.token}
                  hasNoErrors={!hasErrors}
                  handleSuccess={handleSuccess}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(authData, { onError, onSuccess })(EntradasImport);
