import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import modalDialogsActions from "redux/modalDialogs/actions";
import { getInventoryLowExistences } from "services/InventarioService";
import EmtecLoader from "components/common/EmtecLoader";
import { Badge, Table } from "reactstrap";
import { tr } from "date-fns/locale";

const { onError } = modalDialogsActions;

const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    }
  };
};

const LowInventory = ({ authData, onError }) => {
  const [list, setList] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getInventoryLowExistences(setList, authData.token)
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => onError(err));
  }, []);

  if (isLoading) return <EmtecLoader visible={isLoading} />;

  return (
    <div className="roe-card-style">
      <div className="roe-card-header">
        <i className="fas fa-cubes"></i> Productos con bajo inventario
      </div>
      <div className="roe-card-body">
        <div>
          <Table>
            <thead>
              <tr>
                <th>Cód. Interno</th>
                <th>Descripción</th>
                <th>Cant.</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map(product => (
                  <tr key={product.id}>
                    <td>{product.item.internal_code}</td>
                    <td>{product.item.description}</td>
                    <td align="center">
                      <Badge
                        className={
                          product.quantity <
                          process.env.REACT_APP_LOW_INVENTORY_RED
                            ? "c-danger"
                            : "c-warning"
                        }
                      >
                        {product.quantity}
                      </Badge>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default connect(authData, { onError })(LowInventory);
