import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { getRolesList } from "services/UsersService";

const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    }
  };
};

const UsuarioEdit = ({
  handleSubmit,
  modalStatus,
  userData,
  closeModal,
  authData
}) => {
  const [roles, setRoles] = useState([]);
  const [updatedUser, setUpdatedUser] = useState({});

  useEffect(() => {
    getRolesList(setRoles, authData.token);
    setUpdatedUser({ ...userData, role: userData?.role?.name });
  }, [modalStatus, userData, authData]);

  const handleOnChange = data => {
    const key = data.target.id;
    setUpdatedUser({ ...updatedUser, [key]: data.target.value });
  };

  return (
    <div>
      <Modal
        isOpen={modalStatus}
        toggle={() => closeModal()}
        // className={props.className}
      >
        <ModalHeader toggle={() => closeModal()}>
          {userData.username}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="username">Nombre de usuario</Label>
              <Input
                type="text"
                name="username"
                id="username"
                placeholder="cosmefulanito"
                defaultValue={userData.username}
                onChange={handleOnChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="first_name">Nombre</Label>
              <Input
                type="text"
                name="first_name"
                id="first_name"
                placeholder="Karla"
                defaultValue={userData.first_name}
                onChange={handleOnChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="last_name">Apellidos</Label>
              <Input
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Gutierrez"
                defaultValue={userData.last_name}
                onChange={handleOnChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="usuario@emtecacumuladores.com"
                defaultValue={userData.email}
                onChange={handleOnChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="role">Rol</Label>
              <Input
                type="select"
                name="role"
                id="role"
                defaultValue={userData?.role?.name}
                onChange={handleOnChange}
              >
                <option>Ninguno</option>
                {roles?.data &&
                  roles?.data.map(role => {
                    return (
                      <option key={role.id} value={role.name}>
                        {role.display_name}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="email">Contraseña</Label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder=""
                onChange={handleOnChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="email">Repetir contraseña</Label>
              <Input
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                placeholder=""
                onChange={handleOnChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            className="c-primary"
            onClick={() => handleSubmit(updatedUser)}
          >
            Enviar
          </Button>
          <Button className="c-danger" onClick={() => closeModal()}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default connect(authData)(UsuarioEdit);
