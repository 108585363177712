import PageTitle from "components/common/PageTitle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import modalDialogsActions from "redux/modalDialogs/actions";
import {
  getActiveProductsCatalog,
  getInactiveProductsCatalog
} from "services/ProductsService";
import ProductosTable from "./ProductosTable";
import EmtecLoader from "components/common/EmtecLoader";

const { onError, onSuccess } = modalDialogsActions;

const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    }
  };
};

const Productos = ({ authData, onError, onSuccess }) => {
  const [productos, setProductos] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isTrashcan, setIsTrashcan] = useState(false);

  useEffect(() => {
    if (!isTrashcan) {
      getActiveProductsCatalog(setProductos, authData.token)
        .then(() => setRefresh(false))
        .catch(err => onError(err));
    } else {
      getInactiveProductsCatalog(setProductos, authData.token)
        .then(() => setRefresh(false))
        .catch(err => onError(err));
    }
  }, [onError, authData.token, refresh, isTrashcan]);

  const toggleTrashcan = () => {
    setIsTrashcan(isTrashcan => !isTrashcan);
    console.log("Cambió isTrashcan");
    console.log("isTrashcan", isTrashcan);
  };

  return (
    <div>
      <PageTitle
        title="sidebar.datosmaestros.productos"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.datosmaestros"
          },
          {
            name: "sidebar.datosmaestros.productos"
          }
        ]}
      />

      <EmtecLoader visible={productos.length === 0 && !isTrashcan} />

      <ProductosTable
        data={productos}
        //handleUpdate={handleUpdate}
        //handleInactive={handleInactive}
        toggleTrashcan={toggleTrashcan}
        isTrashcan={isTrashcan}
      />
    </div>
  );
};

export default connect(authData, { onError, onSuccess })(Productos);
