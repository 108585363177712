Map.prototype.getKey = function(targetValue) {
  let iterator = this[Symbol.iterator]();
  for (const [key, value] of iterator) {
    if (value === targetValue) return key;
  }
};

const statusHashmap = new Map();
statusHashmap.set("draft", "Borrador");
statusHashmap.set("accepted", "Aceptado");
statusHashmap.set("cancelled", "Cancelado");

const entityTypeHashmap = new Map();
entityTypeHashmap.set("client", "Cliente");
entityTypeHashmap.set("supplier", "Proveedor");
entityTypeHashmap.set("self", "EMTEC");

const personTypeHashmap = new Map();
personTypeHashmap.set("society", "Persona Moral");
personTypeHashmap.set("person", "Persona Física");

const streetTypeHashmap = new Map();
streetTypeHashmap.set("street", "Calle");
streetTypeHashmap.set("avenue", "Avenida");
streetTypeHashmap.set("highway", "Carretera");

const locationTypeHashmap = new Map();
locationTypeHashmap.set("home", "Hogar");
locationTypeHashmap.set("office", "Oficina");
locationTypeHashmap.set("commercial", "Comercial");

export {
  statusHashmap,
  entityTypeHashmap,
  personTypeHashmap,
  streetTypeHashmap,
  locationTypeHashmap
};
