import PageTitle from 'components/common/PageTitle';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class Lotes extends Component {

    render() {

        return (
            <div>
                <PageTitle 
                    title="sidebar.inventario.lotes"
                    className="plr-15"
                    breadCrumb={[
                    {
                        name: "sidebar.inventario"
                    },
                    {
                        name: "sidebar.inventario.lotes"
                    }
                    ]}
                />

                <div className="plr-15">
                    <div className="mtb-30 theme-color">
                        <div className="mtb-10">
                             Herramientas para manejo de lotes.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.themeChanger
    };
}

export default connect(mapStateToProps, null)(Lotes);