import React from "react";

const FilterComponent = tableInstance => {
  const { setAllFilters, flatRows } = tableInstance;
  const { filterValue, setFilter } = tableInstance.column;
  return (
    <input
      type="text"
      value={filterValue || ""}
      onChange={e => {
        if (flatRows.length === 0) setAllFilters([]);
        setFilter(e.target.value || undefined);
      }}
      className="tabl-search react-form-input"
      placeholder={`Filtrar ${tableInstance.column.placeholder}`}
      onClick={e => e.stopPropagation()}
    />
  );
};

export default FilterComponent;
