import React, { useState, useMemo, useCallback, useEffect } from "react";
import { remove } from "lodash";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Pagination from "components/common/Pagination";
import ReactTableWrapper from "components/reacttable/reacttbl.style";
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import HeaderComponent from "components/reacttable/subcomponents/HeaderComponent";
import FilterComponent from "components/reacttable/subcomponents/FilterComponent";
import DropdownFilterComponent from "components/reacttable/subcomponents/DropdownFilterComponent";
import EntityEdit from "./EntityEdit";

const EntitiesTable = props => {
  const [entitiesData, setEntitiesData] = useState(props.data);
  const [entityData, setEntityData] = useState({});
  const [modal, setModal] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);

  useEffect(() => {
    setEntitiesData(props.data);
  }, [props.data]);

  const deleteClick = useCallback(() => {
    props.handleInactive(entityData);
    const array = entitiesData;
    remove(array, function(n) {
      return n.id === entityData.id;
    });
    setEntitiesData([...array]);
    setWarningAlert(false);
  }, [entitiesData, props, entityData]);

  const editClick = useCallback(data => {
    setEntityData(data);
    setModal(true);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Rol"
            />
          );
        },
        Filter: DropdownFilterComponent,
        placeholder: "Rol",
        accessor: "entity_type"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Entidad"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "Entidad",
        accessor: "name"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Razón social"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "Razón social",
        accessor: "branch_offices[0].name"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="RFC"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "RFC",
        accessor: "branch_offices[0].rfc"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Acción"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class wide-cell">
              {/* {<button
                className="react-table-view-button"
                onClick={() => viewClick(tableInstance.row.original)}
              >
                <i className="fas fa-eye" />
              </button>} */}
              <button
                className="react-table-edit-button"
                onClick={() => editClick(tableInstance.row.original)}
              >
                <i className="fas fa-edit" />
              </button>
              <button
                className={`${
                  props.isTrashcan
                    ? "react-table-view-button"
                    : "react-table-delete-button"
                }`}
                onClick={() => {
                  setEntityData(tableInstance.row.original);
                  setWarningAlert(true);
                }}
              >
                <i
                  className={`fas fa-${props.isTrashcan ? "recycle" : "trash"}`}
                />
              </button>
            </div>
          );
        }
      }
    ],
    [editClick, props.isTrashcan]
  );

  const closeModal = () => {
    setEntityData({});
    setModal(false);
  };

  const handleSubmit = data => {
    setEntityData(data);
    props
      .handleUpdate(data)
      .then(() => closeModal())
      .catch(err => {
        throw err;
      });
  };

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setAllFilters,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex, filters }
  } = useTable(
    {
      data: entitiesData,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <ReactTableWrapper {...props}>
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1">
              <span className="hash"># </span> Entidades Fiscales
            </div>
            {filters && filters.length > 0 && (
              <div className="mr-10">
                <Button
                  className="c-btn ma-5 c-info"
                  onClick={() => setAllFilters([])}
                >
                  <i className="fas fa-filter mr-10" />
                  Quitar filtros
                </Button>
              </div>
            )}
            <div className="mr-10">
              <Button
                className="c-btn ma-5 c-primary"
                //onClick={() => formAction("add")}
              >
                <i className="fas fa-plus mr-10" />
                Nuevo
              </Button>
            </div>
            <div>
              <Button
                className="c-btn ma-5 c-warning"
                onClick={props.toggleTrashcan}
              >
                <i
                  className={`mr-10 fas fa-${
                    props.isTrashcan ? "recycle" : "trash"
                  }`}
                />
                Papelera
              </Button>
            </div>
          </div>
          <div className="table-container text-center overflow-auto">
            <table
              border={1}
              className="custom-react-table-theme-class"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(header => (
                      <th
                        {...header.getHeaderProps(
                          header.getSortByToggleProps()
                        )}
                      >
                        <div>{header.render("Header")}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(header => {
                      return (
                        <td
                          {...header.getHeaderProps(
                            header.getSortByToggleProps()
                          )}
                        >
                          <div>
                            {header.canFilter ? header.render("Filter") : null}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            onPageChange={gotoPage}
            pages={pageCount}
            page={pageIndex}
          />
        </div>
        <EntityEdit
          handleSubmit={handleSubmit}
          modalStatus={modal}
          entityData={entityData}
          closeModal={closeModal}
        />
        {!props.isTrashcan && (
          <SweetAlert
            warning
            showCancel
            show={warningAlert}
            confirmBtnText="Sí, deseo borrarlo"
            confirmBtnBsStyle="danger"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="default"
            title="¿Quieres borrar la entidad fiscal?"
            onConfirm={data => {
              deleteClick(data);
            }}
            onCancel={() => {
              setWarningAlert(false);
            }}
          >
            Ya no se podrán emitir nuevos CFDI a esta entidad
          </SweetAlert>
        )}
        {props.isTrashcan && (
          <SweetAlert
            warning
            showCancel
            show={warningAlert}
            confirmBtnText="Sí, deseo restaurarlo"
            confirmBtnBsStyle="info"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="default"
            title="¿Quieres restaurar la entidad fiscal?"
            onConfirm={data => {
              deleteClick(data);
            }}
            onCancel={() => {
              setWarningAlert(false);
            }}
          >
            La entidad fiscal volverá a estar disponible para emisión de CFDI
          </SweetAlert>
        )}
      </ReactTableWrapper>
    </>
  );
};

export default EntitiesTable;
