import React, { useEffect, useState } from "react";
import HeaderWrapper from "./header.style";
import axios from "axios";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import {
  //friend1,
  //friend2,
  //friend3,
  //people1,
  //people2,
  //people3,
  baseUrl
} from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import modalDialogsActions from "redux/modalDialogs/actions";
import { getCurrentUser } from "services/UsersService";

//import PopoverBlock from './PopoverBlock'

const { logout } = AuthActions;
const { onError } = modalDialogsActions;

const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      currentUser: state.auth.currentUser
    }
  };
};

const Header = props => {
  const { authData, drawerMiniMethod, mini } = props;
  const [currentUser, setCurrentUser] = useState(authData.currentUser);

  useEffect(() => {
    getCurrentUser(setCurrentUser, authData.token).catch(error => {
      console.log(error);
      userSignout();
    });
  }, []);

  const userSignout = () => {
    const authorizationToken = authData.token;
    axios
      .delete(`${baseUrl}/logout`, {
        headers: {
          Authorization: authorizationToken
        }
      })
      .then(() => {
        console.log("Gracias por usar EMTEC Dashboard");
        props.logout();
      })
      .catch(err => props.onError(err));
    props.logout();
  };

  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <div className="flex-x align-center">
          <div className="drawer-handle-arrow">
            {mini ? (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            ) : (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            )}
          </div>
          <div
            className="mini-drawer-menu-icon"
            onClick={() => drawerMiniMethod()}
          >
            <i className="fas fa-bars" />{" "}
            <span className="app-name fs-16 bold-text">{"EMTEC"}</span>
          </div>
          <div className="pl-10 flex-1">
            <button
              id="users"
              className="top-header-icon"
              onClick={() => props.history.push("/usuarios/lista")}
            >
              <i className="fas fa-users"></i>
            </button>
          </div>
          {/* <div className="pl-10">
                        <button
                            id="mail"
                            className="top-header-icon"
                        >
                            <i className="far fa-envelope"></i>
                            <div className="button-badge fs-11 demi-bold-text">
                                3
                            </div>
                        </button>
                        <UncontrolledPopover placement="bottom-start" target="mail" className="header-popover" trigger="focus">
                            <PopoverBody className="mail-popover-body">
                                <div className="fs-13 bold-text mb-10">
                                    You have 3 mails.
                                </div>
                                <PopoverBlock
                                    people={friend1}
                                    name="Alex Woods"
                                    text="Hello, How are you ?"
                                    created="Just Now"
                                />
                                <PopoverBlock
                                    people={friend2}
                                    name="James Anderson"
                                    text="Please check your transaction"
                                    created="22nd July 2019"
                                />
                                <PopoverBlock
                                    people={friend3}
                                    name="Watson"
                                    text="You won price.."
                                    created="20th Jun 2019"
                                />
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div> */}
          {/* <div className="pl-10 flex-1">
                        <button
                            id="notification"
                            className="top-header-icon"
                        >
                            <i className="far fa-bell"></i>
                            <div className="button-badge fs-11 demi-bold-text">
                                3
                            </div>
                        </button>
                        <UncontrolledPopover placement="bottom-start" target="notification" className="header-popover" trigger="focus">
                            <PopoverBody className="mail-popover-body">
                                <div className="fs-13 bold-text mb-10">
                                    You have 3 Notifications.
                                </div>
                                <PopoverBlock
                                    people={people1}
                                    name="Juli Hacks"
                                    text="Send You a message..."
                                    created="Just Now"
                                />
                                <PopoverBlock
                                    people={people2}
                                    name="Scarlet JohnSon"
                                    text="Like your status..."
                                    created="22nd July 2019"
                                />
                                <PopoverBlock
                                    people={people3}
                                    name="Andrew Hales"
                                    text="Tagged you in his status"
                                    created="20th Jun 2019"
                                />
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div> */}
          {/*<div className="pl-10">
                        <button
                            className="top-header-icon"
                        >
                            <i className="fas fa-search"></i>
                        </button>
                    </div> */}
          {currentUser && (
            <div className="pl-10">
              <div
                id="profile"
                className="text-white"
                onClick={() => props.history.push("/intro")}
              >
                <strong>{currentUser?.full_name}</strong>
                <br />
                {currentUser?.role.display_name}
              </div>
              <UncontrolledPopover
                className="roy-menu"
                innerClassName="roy-inner-content"
                placement="bottom-end"
                target="profile"
                trigger="legacy"
              >
                <PopoverBody>
                  <div
                    className="roy-menu-list"
                    onClick={() => props.history.push("/profile")}
                  >
                    Mi Perfil
                  </div>
                  <div className="roy-menu-list">Ajustes</div>
                  <div className="roy-menu-list" onClick={userSignout}>
                    Cerrar sesión
                  </div>
                </PopoverBody>
              </UncontrolledPopover>
            </div>
          )}
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default compose(
  withRouter,
  connect(authData, { logout, onError })
)(Header);
