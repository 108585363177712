import axios from "axios";
import { baseUrl } from "helper/constant";

const buildHeaders = token => {
  return {
    headers: {
      Authorization: token
    }
  };
};

const getInvoices = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/invoices?per_page=1000`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};
const getInvoicesByState = async (state, token, status) => {
  const response = await axios
    .get(`${baseUrl}/invoices`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  const filteredData = response.data.invoices.filter(
    invoice => invoice.state === status
  );
  state(filteredData);
};

export { getInvoices, getInvoicesByState };
