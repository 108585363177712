import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import { getCurrentUser } from "services/UsersService";
import LowInventory from "./modules/homecards/lowInventory";

const Intro = ({ sidebarTheme, authData }) => {
  const activeColor = {
    color: sidebarTheme.activeColor
  };
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    getCurrentUser(setCurrentUser, authData.token);
  }, []);

  return (
    <div>
      <PageTitle title="sidebar.intro" />

      <div className="plr-15">
        <div className="mtb-30 theme-color">
          <div className="introduction" style={activeColor}>
            Hola, {authData.currentUser.first_name}
          </div>
          <div className="mtb-10">
            {currentUser && (
              <div className="roe-card-style">
                <div className="roe-card-header">{currentUser.full_name}</div>
                <div className="roe-card-body">
                  <p>Usuario: {currentUser.username}</p>
                  <p>Email: {currentUser.email}</p>
                  <p>Rol: {currentUser.role.display_name}</p>
                  <p>Permisos:</p>
                  <ul>
                    {currentUser.role.permissions &&
                      currentUser.role.permissions.map(p => (
                        <li key={p.id}>{p.description}</li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <LowInventory />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      currentUser: state.auth.currentUser
    },
    ...state.themeChanger
  };
};

export default connect(mapStateToProps)(Intro);
