import React from "react";
import { formatDate, toCurrency } from "util/utils";
const EntradasPreImport = ({ data: json }) => {
  return (
    <div className="roe-card-style mtb-15">
      <div className="roe-card-header">
        <h2>
          El CDFI folio {json.elements[0].attributes.Folio} está listo para
          importarse
        </h2>
      </div>
      <div className="roe-card-body container">
        <div className="row">
          <div className="col">
            <h3>Emisor</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-2">RFC</div>
          <div className="col">
            {json.elements[0].elements[0].attributes.Rfc}
          </div>
        </div>
        <div className="row">
          <div className="col-2">Razón social</div>
          <div className="col">
            {json.elements[0].elements[0].attributes.Nombre}
          </div>
        </div>
        <div className="row">
          <div className="col-2">Fecha</div>
          <div className="col">
            {formatDate(json.elements[0].attributes.Fecha)}
          </div>
        </div>
        <div className="row">
          <div className="col-2">Total</div>
          <div className="col">
            {toCurrency(json.elements[0].attributes.Total)}
          </div>
        </div>
        <div className="row">
          <div className="col-2">A importar</div>
          <div className="col">
            {Object.keys(json.elements[0].elements[2].elements).length}{" "}
            conceptos
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col">
            <small>
              Al hacer clic en <strong>Importar</strong> se analizarán los
              conceptos, y en caso de haber errores, se le pedirá que resuelva
              los conflictos antes de proceder a incrementar las existencias.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntradasPreImport;
