import React from "react";
import { statusHashmap } from "util/data/constants";

function getPropertyValue(obj, path) {
  const keys = path.split(".");
  let result = obj;

  for (const key of keys) {
    if (result && typeof result === "object") {
      result = result[key];
    } else {
      return undefined; // Devuelve undefined si alguna propiedad no existe
    }
  }

  return result;
}

const DropdownFilterComponent = tableInstance => {
  const { setAllFilters, flatRows } = tableInstance;
  const { filterValue, setFilter } = tableInstance.column;
  const theValues = tableInstance.column.preFilteredRows;
  const theKey = tableInstance.column.id;
  const values = [
    ...new Set(theValues.map(item => getPropertyValue(item.original, theKey)))
  ]
    .filter(category => category !== null)
    .sort()
    .sort((a, b) => a - b);

  if (values.length < 1)
    return (
      <input
        type="text"
        readOnly
        placeholder="No hay opciones"
        className="tabl-search react-form-input"
      />
    );

  return (
    <select
      value={filterValue || ""}
      onChange={e => {
        if (flatRows.length === 0) setAllFilters([]);
        setFilter(e.target.value || undefined);
      }}
      className="tabl-search react-form-input"
      onClick={e => e.stopPropagation()}
    >
      <option value="">Todo</option>
      <option value="" disabled>
        ------
      </option>
      {values &&
        values.map(theValue => (
          <option key={theValue} value={theValue}>
            {statusHashmap.get(theValue) || theValue}
          </option>
        ))}
    </select>
  );
};

export default DropdownFilterComponent;
