import moment from "moment";

export const toCurrency = (numberString, decimals = 2) => {
  if (numberString == null) return "N/A";
  let number = parseFloat(numberString);
  if (decimals > 0) {
    number = +number.toFixed(decimals);
  }
  return `$ ${number.toLocaleString("MXN")}`;
};

export const formatDate = (dateString, formatString = "D/MM/YYYY HH:mm:ss") => {
  if (dateString == null) return "";
  return moment(dateString).format(formatString);
};
