import axios from "axios";
import { baseUrl } from "helper/constant";

const buildHeaders = token => {
  return {
    headers: {
      Authorization: token
    }
  };
};

const getInventoryList = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/products/inventory`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data.data.products);
};

const getInventoryLowExistences = async (state, token) => {
  const response = await axios
    .get(
      `${baseUrl}/products/inventory?q[quantity_lteq]=${process.env.REACT_APP_LOW_INVENTORY_YELLOW}&q[s]=quantity`,
      buildHeaders(token)
    )
    .catch(err => {
      throw err;
    });
  state(response.data.data.products);
};

const getAcceptImportedInvoice = async (state, token, invoiceId) => {
  const response = await axios
    .get(`${baseUrl}/invoices/${invoiceId}/accept`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

const getCancelImportedInvoice = async (state, token, invoiceId) => {
  const response = await axios
    .get(`${baseUrl}/invoices/${invoiceId}/cancel`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

const postInvoicesLoadJson = async (state, token, body) => {
  const response = await axios
    .post(`${baseUrl}/invoices/load_json`, body, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  state(response.data);
};

const postInvoiceFixJson = async (state, token, body, invoiceId) => {
  const response = await axios
    .post(
      `${baseUrl}/invoices/${invoiceId}/load_fixed_invoice`,
      body,
      buildHeaders(token)
    )
    .catch(err => {
      throw err;
    });
  state(response.data);
};

export {
  getInventoryList,
  getInventoryLowExistences,
  getAcceptImportedInvoice,
  getCancelImportedInvoice,
  postInvoicesLoadJson,
  postInvoiceFixJson
};
