import React, { useState, useMemo, useCallback, useEffect } from "react";
import { remove } from "lodash";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import classnames from "classnames";
import Pagination from "components/common/Pagination";
import { formatDate, toCurrency } from "util/utils";
import ReactTableWrapper from "components/reacttable/reacttbl.style";
import SweetAlert from "react-bootstrap-sweetalert";
import EntradasImportedDetail from "./importCfdi/EntradasImportedDetail";
import FilterComponent from "components/reacttable/subcomponents/FilterComponent";
import HeaderComponent from "components/reacttable/subcomponents/HeaderComponent";
import DropdownFilterComponent from "components/reacttable/subcomponents/DropdownFilterComponent";
import { Button } from "reactstrap";
import { statusHashmap } from "util/data/constants";

const EntradasTable = props => {
  const [entradasData, setEntradasData] = useState(props.data);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [auditableId, setAuditableId] = useState(null);

  useEffect(() => {
    setEntradasData(props.data);
  }, [props.data]);

  const deleteClick = useCallback(
    data => {
      // Here you can view the data and delete through API calling
      const array = entradasData;
      remove(array, function(n) {
        return n.id === data.id;
      });
      setEntradasData([...array]);
    },
    [entradasData]
  );

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Fecha"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "Fecha",
        accessor: "updated_at",
        Cell: props => <div> {formatDate(props.value)} </div>
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Tipo de documento"
            />
          );
        },
        Filter: DropdownFilterComponent,
        placeholder: "Tipo",
        accessor: "document_type"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Estado"
            />
          );
        },
        Filter: DropdownFilterComponent,
        placeholder: "Estado",
        accessor: "state",
        Cell: props => <div>{statusHashmap.get(props.value)}</div>
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Monto"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "Monto",
        accessor: "breakdown.total",
        Cell: props => <div> {toCurrency(props.value)} </div>
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Proveedor"
            />
          );
        },
        Filter: DropdownFilterComponent,
        placeholder: "Proveedor",
        accessor: "sender.name"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Acción"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class wide-cell">
              {
                <EntradasImportedDetail
                  key={tableInstance.row.original.id}
                  invoice={tableInstance.row.original}
                />
              }
              {tableInstance.row.original.state === "accepted" && (
                <button
                  className="react-table-edit-button"
                  onClick={() => editClick(tableInstance.row.original)}
                >
                  <i className="fas fa-edit" /> Cancelar{" "}
                  {tableInstance.row.original.id}
                </button>
              )}
              {/* <button
                className="react-table-delete-button"
                onClick={() => deleteClick(tableInstance.row.original)}
              >
                <i className="fas fa-trash" />
              </button> */}
            </div>
          );
        }
      }
    ],
    [deleteClick]
  );

  const confirmCancelAuditable = useCallback(
    auditable_id => {
      props.handleUpdate(auditable_id);
      setShowSweetAlert(false);
    },
    [props.entradasData]
  );

  const editClick = data => {
    setShowSweetAlert(true);
    setAuditableId(data.id);
  };

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setAllFilters,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex, filters }
  } = useTable(
    {
      data: entradasData,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0,
        sortBy: [
          {
            id: "updated_at",
            desc: true
          }
        ]
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <ReactTableWrapper {...props}>
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1">
            <span className="hash"># </span> Entradas
            <br />
          </div>
          {filters && filters.length > 0 && (
            <div className="mr-10">
              <Button
                className="c-btn ma-5 c-info"
                onClick={() => setAllFilters([])}
              >
                <i className="fas fa-filter mr-10" />
                Quitar filtros
              </Button>
            </div>
          )}
        </div>
        <div className="table-container text-center overflow-auto">
          <table
            border={1}
            className="custom-react-table-theme-class"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => (
                    <th
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                    >
                      <div>{header.render("Header")}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => {
                    return (
                      <td
                        {...header.getHeaderProps(
                          header.getSortByToggleProps()
                        )}
                      >
                        <div>
                          {header.canFilter ? header.render("Filter") : null}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          onPageChange={gotoPage}
          pages={pageCount}
          page={pageIndex}
        />
      </div>

      <SweetAlert
        warning
        showCancel
        show={showSweetAlert}
        confirmBtnText="Sí, deseo anularla"
        confirmBtnBsStyle="danger"
        cancelBtnText="Cancelar"
        cancelBtnBsStyle="default"
        title="¿Quieres anular esta entrada?"
        onConfirm={() => {
          confirmCancelAuditable(auditableId);
        }}
        onCancel={() => {
          setShowSweetAlert(false);
        }}
      >
        Al anular la entrada, el inventario se verá afectado reduciendo las
        existencias de los productos relacionados.
      </SweetAlert>
    </ReactTableWrapper>
  );
};

export default EntradasTable;
