import modalDialogsActions from "./actions";

const initState = {
  dialogType: null,
  dialogHeader: null,
  dialogCssClass: null,
  dialogContent: null,
  dialogDetails: null
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case modalDialogsActions.SUCCESS:
      return {
        ...state,
        dialogType: modalDialogsActions.SUCCESS,
        dialogHeader: modalDialogsActions.SUCCESS_HEADER,
        dialogCssClass: modalDialogsActions.SUCCESS_CSS_CLASS,
        dialogContent: action.dialogContent,
        dialogDetails: null
      };
    case modalDialogsActions.WARNING:
      return {
        ...state,
        dialogType: modalDialogsActions.WARNING,
        dialogHeader: modalDialogsActions.WARNING_HEADER,
        dialogCssClass: modalDialogsActions.WARNING_CSS_CLASS,
        dialogContent: action.dialogContent,
        dialogDetails: null
      };
    case modalDialogsActions.ERROR:
      return {
        ...state,
        dialogType: modalDialogsActions.ERROR,
        dialogHeader: modalDialogsActions.ERROR_HEADER,
        dialogCssClass: modalDialogsActions.ERROR_CSS_CLASS,
        dialogContent: action.dialogContent,
        dialogDetails: action.dialogDetails
      };
    case modalDialogsActions.CLEAR:
      return {
        ...state,
        dialogType: null,
        dialogHeader: null,
        dialogCssClass: null,
        dialogContent: null,
        dialogDetails: null
      };
    default:
      return state;
  }
}
