export const sidebarData = [
  {
    name: "sidebar.datosmaestros",
    iconClass: "fas fa-chart-area",
    child: [
      {
        listname: "sidebar.datosmaestros.entidades",
        routepath: "/datosmaestros/entidades",
        shortname: "CL"
      },
      {
        listname: "sidebar.datosmaestros.productos",
        routepath: "/datosmaestros/productos",
        shortname: "PR"
      }
      /*{
        listname: "sidebar.datosmaestros.descuentos",
        routepath: "/datosmaestros/descuentos",
        shortname: "DE",
      },*/
    ]
  },
  /*{
    name: "sidebar.ventas",
    iconClass: "fas fa-file-invoice-dollar",
    child: [
      {
        listname: "sidebar.ventas.facturas",
        routepath: "/ventas/facturas",
        shortname: "Fa",
      },
      {
        listname: "sidebar.ventas.cotizaciones",
        routepath: "/ventas/cotizaciones",
        shortname: "Co",
      },
      {
        listname: "sidebar.ventas.pedidos",
        routepath: "/ventas/pedidos",
        shortname: "Pe",
      },
      {
        listname: "sidebar.ventas.reportes",
        routepath: "/ventas/reportes",
        shortname: "Re",
      },
      {
        listname: "sidebar.ventas.subfamilia",
        routepath: "/ventas/subfamilia",
        shortname: "Sf",
      },
    ],
  },
  {
    name: "sidebar.egresos",
    iconClass: "fas fa-receipt",
    child: [
      {
        listname: "sidebar.egresos.facturas",
        routepath: "/egresos/facturas",
        shortname: "Fa",
      },
      {
        listname: "sidebar.egresos.ordenes",
        routepath: "/egresos/ordenes",
        shortname: "Or",
      },
      {
        listname: "sidebar.egresos.reportes",
        routepath: "/egresos/reportes",
        shortname: "Re",
      },
    ],
  },
  {
    name: "sidebar.nomina",
    iconClass: "fas fa-users",
    child: [
      {
        listname: "sidebar.nomina.comprobantes",
        routepath: "/nomina/comprobantes",
        shortname: "Co",
      },
      {
        listname: "sidebar.nomina.timbrado",
        routepath: "/nomina/timbrado",
        shortname: "Ti",
      },
      {
        listname: "sidebar.nomina.empleados",
        routepath: "/nomina/empleados",
        shortname: "Em",
      },
      {
        listname: "sidebar.nomina.reportes",
        routepath: "/nomina/reportes",
        shortname: "Re",
      },
    ],
  },
  {
    name: "sidebar.tesoreria",
    iconClass: "fas fa-wallet",
    child: [
      {
        listname: "sidebar.tesoreria.conciliacion",
        routepath: "/tesoreria/conciliacion",
        shortname: "Ex1",
      },
      {
        listname: "sidebar.tesoreria.bancosycajas",
        routepath: "/tesoreria/bancosycajas",
        shortname: "BC",
      },
      {
        listname: "sidebar.tesoreria.cobros",
        routepath: "/tesoreria/cobros",
        shortname: "Co",
      },
      {
        listname: "sidebar.tesoreria.pagos",
        routepath: "/tesoreria/pagos",
        shortname: "Pa",
      },
      {
        listname: "sidebar.tesoreria.cancelacionesdesaldo",
        routepath: "/tesoreria/cancelacionesdesaldo",
        shortname: "CS",
      },
      {
        listname: "sidebar.tesoreria.complementosemitidos",
        routepath: "/tesoreria/complementosemitidos",
        shortname: "CE",
      },
      {
        listname: "sidebar.tesoreria.complementosrecibidos",
        routepath: "/tesoreria/complementosrecibidos",
        shortname: "CR",
      },
      {
        listname: "sidebar.tesoreria.reportes",
        routepath: "/tesoreria/reportes",
        shortname: "Re",
      },
      {
        listname: "sidebar.tesoreria.aprobacion",
        routepath: "/tesoreria/aprobacion",
        shortname: "Ap",
      },
      {
        listname: "sidebar.tesoreria.reportelth",
        routepath: "/tesoreria/reportelth",
        shortname: "RL",
      },
      {
        listname: "sidebar.tesoreria.crearreportecobranza",
        routepath: "/tesoreria/crearreportecobranza",
        shortname: "CR",
      },
      {
        listname: "sidebar.tesoreria.herramientas",
        routepath: "/tesoreria/herramientas",
        shortname: "He",
      },
    ],
  },*/
  {
    name: "sidebar.inventario",
    iconClass: "fas fa-warehouse",
    child: [
      {
        listname: "sidebar.inventario.existencias",
        routepath: "/inventario/existencias",
        shortname: "In"
      },
      {
        listname: "sidebar.inventario.entradas",
        routepath: "/inventario/entradas",
        shortname: "En"
      }
      /*{
        listname: "sidebar.inventario.salidas",
        routepath: "/inventario/salidas",
        shortname: "Sa",
      },
      {
        listname: "sidebar.inventario.traspasos",
        routepath: "/inventario/traspasos",
        shortname: "Tr",
      },
      {
        listname: "sidebar.inventario.almacenes",
        routepath: "/inventario/almacenes",
        shortname: "Al",
      },
      {
        listname: "sidebar.inventario.reportes",
        routepath: "/inventario/reportes",
        shortname: "Re",
      },
      {
        listname: "sidebar.inventario.cartaporteemitidas",
        routepath: "/inventario/cartaporteemitidas",
        shortname: "CP",
      },
      {
        listname: "sidebar.inventario.lotes",
        routepath: "/inventario/lotes",
        shortname: "Lo",
      },
      {
        listname: "sidebar.inventario.reportedeinventariolth",
        routepath: "/inventario/reportedeinventariolth",
        shortname: "RI",
      },
      {
        listname: "sidebar.inventario.crearreportesurtimiento",
        routepath: "/inventario/crearreportesurtimiento",
        shortname: "RS",
      },*/
    ]
  }
  /*{
    name: "sidebar.usados",
    iconClass: "fas fa-band-aid",
    child: [
      {
        listname: "sidebar.usados.recepcion",
        routepath: "/usados.recepcion",
        shortname: "Re",
      },
      {
        listname: "sidebar.usados.notadecredito",
        routepath: "/usados.notadecredito",
        shortname: "NC",
      },
      {
        listname: "sidebar.usados.envio",
        routepath: "/usados.envio",
        shortname: "En",
      },
      {
        listname: "sidebar.usados.reporte",
        routepath: "/usados.reporte",
        shortname: "Re",
      },
    ],
  },
  {
    name: "sidebar.ajustes",
    iconClass: "fas fa-exchange-alt",
    child: [
      {
        listname: "sidebar.ajustes.recepcion",
        routepath: "/ajustes.recepcion",
        shortname: "Re",
      },
      {
        listname: "sidebar.ajustes.notadecredito",
        routepath: "/ajustes.notadecredito",
        shortname: "NC",
      },
      {
        listname: "sidebar.ajustes.envio",
        routepath: "/ajustes.envio",
        shortname: "En",
      },
      {
        listname: "sidebar.ajustes.tabla",
        routepath: "/ajustes.tabla",
        shortname: "Ta",
      },
    ],
  },
  {
    name: "sidebar.devoluciones",
    iconClass: "fas fa-undo-alt",
    child: [
      {
        listname: "sidebar.devoluciones.recepcion",
        routepath: "/devoluciones.recepcion",
        shortname: "Re",
      },
      {
        listname: "sidebar.devoluciones.notadecredito",
        routepath: "/devoluciones.notadecredito",
        shortname: "NC",
      },
      {
        listname: "sidebar.devoluciones.envio",
        routepath: "/devoluciones.envio",
        shortname: "En",
      },
      {
        listname: "sidebar.devoluciones.pos",
        routepath: "/devoluciones.pos",
        shortname: "PO",
      },
    ],
  },
  {
    name: "sidebar.auditoria",
    iconClass: "fas fa-calculator",
    child: [
      {
        listname: "sidebar.auditoria.cfdicancelados",
        routepath: "/auditoria.cfdicancelados",
        shortname: "CC",
      },
      {
        listname: "sidebar.auditoria.documentosnocont",
        routepath: "/auditoria.documentosnocont",
        shortname: "NC",
      },
      {
        listname: "sidebar.auditoria.cuadre",
        routepath: "/auditoria.cuadre",
        shortname: "Cu",
      },
      {
        listname: "sidebar.auditoria.reporte",
        routepath: "/auditoria.reporte",
        shortname: "Re",
      },
    ],
  },*/
];

// Comments:::::::

//  If you want one level child then look below example

/*
  {
    name: 'sidebar.forms',
    iconClass: 'fab fa-wpforms',
    child: [
      {
        listname: 'sidebar.regularforms',
        routepath: '/regularform',
        shortname: 'RF'
      }
    ]
  }
*/

//  If you want Second level child then look below example

/*
   {
      name: 'sidebar.pages',
      iconClass: 'fas fa-print',
      child: [
        {
          listname: 'sidebar.authentication',
          iconClass: 'fas fa-user',
          child: [
            {
              listname: 'sidebar.login',
              routepath: '/login',
              shortname: 'L'
            },
          ]
        }
      ]
    }
*/

export const HorizontalSidebarData = [
  {
    name: "sidebar.intro",
    routepath: "/Intro",
    iconClass: "fas fa-chalkboard"
  }
];

// ### For Horizontal sidebar

//     <!-- Basics -->
//         {
//             name: "sidebar.single",
//             iconClass: "fab fa-stripe-s",
//             routepath: "/single"
//         }
//     <!-- One Level -->
//         {
//             name: "sidebar.onelevel",
//             iconClass: "fas fa-expand",
//             child: [
//                 {
//                     name: "sidebar.example",
//                     routepath: "/ex",
//                 }
//             ]
//         }
//     <!-- Second level -->
//         {
//             name: "sidebar.secondlevel",
//             iconClass: "fas fa-expand",
//             child: [
//                 {
//                     name: "sidebar.example",
//                     iconClass: "fas fa-plus",
//                     child: [
//                         {
//                             name: "sidebar.example1",
//                             routepath: "/ex1",
//                         },
//                         {
//                             name: "sidebar.example2",
//                             routepath: "/ex2",
//                         }
//                     ]
//                 }
//             ]
//         }
