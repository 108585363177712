import EmtecLoader from "components/common/EmtecLoader";
import PageTitle from "components/common/PageTitle";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import modalDialogsActions from "redux/modalDialogs/actions";
import {
  createFiscalEntity,
  getClients,
  getFiscalEntities,
  getSuppliers,
  updateFiscalEntityById
} from "services/FiscalEntitiesService";
import EntitiesTable from "./EntitiesTable";

const { onError, onSuccess } = modalDialogsActions;

const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    }
  };
};

const FiscalEntities = ({ authData, onError, onSuccess }) => {
  const [entities, setEntities] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isTrashcan, setIsTrashcan] = useState(false);
  const [entityType, setEntityType] = useState("all");

  useEffect(() => {
    switch (entityType) {
      case "clients":
        getClients(setEntities, authData.token)
          .then(() => setRefresh(false))
          .catch(err => onError(err));
        break;
      case "suppliers":
        getSuppliers(setEntities, authData.token)
          .then(() => setRefresh(false))
          .catch(err => onError(err));
        break;
      default:
        getFiscalEntities(setEntities, authData.token)
          .then(() => setRefresh(false))
          .catch(err => onError(err));
    }
  }, [onError, authData.token, refresh, isTrashcan]);

  const toggleTrashcan = () => {
    setIsTrashcan(isTrashcan => !isTrashcan);
    console.log("Cambió isTrashcan");
    console.log("isTrashcan", isTrashcan);
  };

  const handleUpdate = async updatedEntity => {
    const payload = {
      fiscal_entity: updatedEntity
    };
    if (payload.fiscal_entity.id) {
      await updateFiscalEntityById(
        () => null,
        authData.token,
        payload,
        payload.fiscal_entity.id
      )
        .then(() =>
          onSuccess({
            message:
              "Los datos de la entidad fiscal se actualizaron exitosamente"
          })
        )
        .catch(onError);
    } else {
      await createFiscalEntity(() => null, authData.token, payload)
        .then(() =>
          onSuccess({
            message: "Se creó la nueva entidad fiscal exitosamente"
          })
        )
        .catch(onError);
    }
    setRefresh(true);
  };

  return (
    <div>
      <PageTitle
        title="sidebar.datosmaestros.entidades"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.datosmaestros"
          },
          {
            name: "sidebar.datosmaestros.entidades"
          }
        ]}
      />

      <EmtecLoader visible={entities.length == 0 && !isTrashcan} />

      <EntitiesTable
        data={entities}
        handleUpdate={handleUpdate}
        //handleInactive={handleInactive}
        toggleTrashcan={toggleTrashcan}
        isTrashcan={isTrashcan}
      />
    </div>
  );
};

export default connect(authData, { onError, onSuccess })(FiscalEntities);
