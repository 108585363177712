import CustomToast from "components/notifications/CustomToast";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import modalDialogsActions from "redux/modalDialogs/actions";

const { clearMessage } = modalDialogsActions;

const ModalMessage = ({ clearMessage }) => {
  let type = useSelector(state => state.modalDialogs.dialogType);
  let header = useSelector(state => state.modalDialogs.dialogHeader);
  let content = useSelector(state => state.modalDialogs.dialogContent);
  let details = useSelector(state => state.modalDialogs.dialogDetails);
  let cssClass = useSelector(state => state.modalDialogs.dialogCssClass);

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(!!type);
    if (type) {
      setTimeout(() => {
        clearMessage();
      }, 8000);
    }
  }, [type]);

  return (
    <CustomToast
      heading={header}
      width={600}
      show={show}
      transition={true}
      position="bottom-right"
      className={cssClass}
      message={content}
      details={details}
      onCloseCLick={() => clearMessage()}
    />
  );
};
export default connect(null, { clearMessage })(ModalMessage);
