import axios from "axios";
import { baseUrl } from "helper/constant";

const buildHeaders = token => {
  return {
    headers: {
      Authorization: token
    }
  };
};

const getProductByInternalCode = async (state, token, internalCode) => {
  const response = await axios
    .get(
      `${baseUrl}/products/catalog?q[internal_code_eq]=${internalCode}`,
      buildHeaders(token)
    )
    .catch(err => {
      throw err;
    });
  state(response.data.data.products[0]);
};

const getActiveProductsCatalog = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/products/catalog`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  const filteredData = response.data.data.products.filter(
    user => user.active === true
  );
  state(filteredData);
};

const getInactiveProductsCatalog = async (state, token) => {
  const response = await axios
    .get(`${baseUrl}/products/catalog`, buildHeaders(token))
    .catch(err => {
      throw err;
    });
  const filteredData = response.data.data.products.filter(
    data => data.active === false
  );
  state(filteredData);
};

export {
  getProductByInternalCode,
  getActiveProductsCatalog,
  getInactiveProductsCatalog
};
