import PageTitle from "components/common/PageTitle";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import EntradasImport from "./importCfdi/entradasImport";
import EntradasTable from "./EntradasTable";
import modalDialogsActions from "redux/modalDialogs/actions";
import { getInvoices } from "services/InvoicesService";
import { getCancelImportedInvoice } from "services/InventarioService";
import EmtecLoader from "components/common/EmtecLoader";

const { onError, onSuccess } = modalDialogsActions;

const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    }
  };
};

const Entradas = ({ authData, onError, onSuccess }) => {
  const [entradas, setEntradas] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isTrashcan, setIsTrashcan] = useState(false);

  useEffect(() => {
    if (!isTrashcan) {
      getInvoices(setEntradas, authData.token)
        .then(() => {
          setRefresh(false);
        })
        .catch(err => onError(err));
    }
  }, [onError, authData.token, refresh, isTrashcan]);

  const handleReloadData = () => {
    console.log("refreshed");
    setRefresh(true);
  };

  const handleUpdate = invoice_id => {
    console.log("handleUpdate: id de factura", invoice_id);
    getCancelImportedInvoice(() => null, authData.token, invoice_id)
      .then(() => {
        onSuccess({
          message:
            "El documento se anuló y el inventario se actualizó exitosamente"
        });
        setEntradas([]);
        handleReloadData();
      })
      .catch(onError);
  };

  /*const toggleTrashcan = () => {
    setIsTrashcan(isTrashcan => !isTrashcan);
    console.log("Cambió isTrashcan");
    console.log("isTrashcan", isTrashcan);
  };*/

  return (
    <div>
      <PageTitle
        title="sidebar.inventario.entradas"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.inventario"
          },
          {
            name: "sidebar.inventario.entradas"
          }
        ]}
      />

      <EmtecLoader visible={entradas.length === 0} />

      <EntradasImport
        handleReloadData={handleReloadData}
        style={{ maxWidth: "100%" }}
      />

      {entradas.length === 0 && <p>Cargando...</p>}

      {entradas?.data?.invoices && (
        <EntradasTable
          data={entradas.data.invoices}
          meta={entradas.meta}
          handleUpdate={handleUpdate}
          //handleInactive={handleInactive}
          //toggleTrashcan={toggleTrashcan}
          //isTrashcan={isTrashcan}
        />
      )}
    </div>
  );
};

export default connect(authData, { onError, onSuccess })(Entradas);
