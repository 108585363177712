import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col
} from "reactstrap";
import {
  entityTypeHashmap,
  locationTypeHashmap,
  personTypeHashmap,
  streetTypeHashmap
} from "util/data/constants";

const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    }
  };
};

function removeTimestamps(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === "created_at" || key === "updated_at") {
        delete obj[key];
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        removeTimestamps(obj[key]);
      }
    }
  }
  return obj;
}

function preparePayload(original) {
  if (!!!original.name) return;
  if (!!original.branch_office) return original;

  console.log("Original", original);

  removeTimestamps(original);

  const transformed = {
    name: original.name,
    entity_type: entityTypeHashmap.getKey(original.entity_type),
    id: original.id,
    branch_office: { ...original.branch_offices[0] }
  };

  transformed.branch_office.person_type = personTypeHashmap.getKey(
    transformed.branch_office.person_type
  );
  transformed.branch_office.address.location_type = locationTypeHashmap.getKey(
    transformed.branch_office.address.location_type
  );
  transformed.branch_office.address.street_type = streetTypeHashmap.getKey(
    transformed.branch_office.address.street_type
  );

  return transformed;
}

const EntityEdit = ({ handleSubmit, modalStatus, entityData, closeModal }) => {
  const [updatedEntity, setUpdatedEntity] = useState();
  const leftColSize = 4;
  const rightColSize = 8;

  useEffect(() => {
    setUpdatedEntity(preparePayload(entityData));
  }, [entityData]);

  const updateField = (path, value) => {
    setUpdatedEntity(prevState => {
      const newState = { ...prevState };
      const keys = path.split(".");
      let current = newState;

      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }

      current[keys[keys.length - 1]] = value;
      return newState;
    });
  };

  const handleOnChange = e => {
    const { id, value } = e.target;
    updateField(id, value);
  };

  const handleSubmitHere = () => {
    handleSubmit(updatedEntity);
  };

  return (
    <div>
      {updatedEntity && (
        <Modal
          isOpen={modalStatus}
          toggle={() => closeModal()}
          className="modal-xl"
        >
          <ModalHeader toggle={() => closeModal()}>
            {updatedEntity.name}
          </ModalHeader>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col">
                  <FormGroup row>
                    <Label for="name" sm={leftColSize}>
                      Cliente
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nombre"
                        defaultValue={updatedEntity.name}
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="entity_type" sm={leftColSize}>
                      Rol
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="select"
                        name="entity_type"
                        id="entity_type"
                        value={updatedEntity.entity_type}
                        onChange={handleOnChange}
                      >
                        {Array.from(entityTypeHashmap.entries()).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          )
                        )}
                      </Input>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="branch_office_name" sm={leftColSize}>
                      Razón social
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="branch_office_name"
                        id="branch_office.name"
                        placeholder="Empresa SA"
                        defaultValue={updatedEntity?.branch_office?.name}
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="branch_office_rfc" sm={leftColSize}>
                      RFC
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="branch_office_rfc"
                        id="branch_office.rfc"
                        placeholder="Empresa SA"
                        defaultValue={updatedEntity?.branch_office?.rfc}
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup row>
                    <Label for="description" sm={leftColSize}>
                      Descripción
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="description"
                        id="branch_office.address.description"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.description
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="location_type" sm={leftColSize}>
                      Tipo
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="select"
                        name="location_type"
                        id="branch_office.address.location_type"
                        value={
                          updatedEntity.branch_office.address.location_type
                        }
                        onChange={handleOnChange}
                      >
                        {Array.from(locationTypeHashmap.entries()).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          )
                        )}
                      </Input>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="postal_code" sm={leftColSize}>
                      C. postal
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="postal_code"
                        id="branch_office.address.postal_code"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.postal_code
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="state" sm={leftColSize}>
                      Estado
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="state"
                        id="branch_office.address.state"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.state
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="municipality" sm={leftColSize}>
                      Municipio
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="municipality"
                        id="branch_office.address.municipality"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.municipality
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="locality" sm={leftColSize}>
                      Localidad
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="locality"
                        id="branch_office.address.locality"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.locality
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup row>
                    <Label for="street" sm={leftColSize}>
                      Calle
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="street"
                        id="branch_office.address.street"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.street
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="street_type" sm={leftColSize}>
                      Tipo de vialidad
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="select"
                        name="street_type"
                        id="branch_office.address.street_type"
                        value={updatedEntity.branch_office.address.street_type}
                        onChange={handleOnChange}
                      >
                        {Array.from(streetTypeHashmap.entries()).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          )
                        )}
                      </Input>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="ext_number" sm={leftColSize}>
                      Número Ext.
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="ext_number"
                        id="branch_office.address.ext_number"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.ext_number
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="int_number" sm={leftColSize}>
                      Número Int.
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="int_number"
                        id="branch_office.address.int_number"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.int_number
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="crossing_1" sm={leftColSize}>
                      Entre
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="crossing_1"
                        id="branch_office.address.crossing_1"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.crossing_1
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="crossing_2" sm={leftColSize}>
                      y
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="crossing_2"
                        id="branch_office.address.crossing_2"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.crossing_2
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="suburb" sm={leftColSize}>
                      Colonia
                    </Label>
                    <Col sm={rightColSize}>
                      <Input
                        type="text"
                        name="suburb"
                        id="branch_office.address.suburb"
                        defaultValue={
                          updatedEntity?.branch_office?.address?.suburb
                        }
                        onChange={handleOnChange}
                      />
                    </Col>
                  </FormGroup>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="c-primary" onClick={handleSubmitHere}>
              Enviar
            </Button>
            <Button className="c-danger" onClick={() => closeModal()}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default connect(authData)(EntityEdit);
