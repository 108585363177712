import PageTitle from "components/common/PageTitle";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import modalDialogsActions from "redux/modalDialogs/actions";
import { getInventoryList } from "services/InventarioService";
import ExistenciasTable from "./ExistenciasTable";
import EmtecLoader from "components/common/EmtecLoader";

const { onError, onSuccess } = modalDialogsActions;

const authData = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    }
  };
};

const Existencias = ({ authData, onError, onSuccess }) => {
  const [items, setItems] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isTrashcan, setIsTrashcan] = useState(false);

  useEffect(() => {
    if (!isTrashcan) {
      getInventoryList(setItems, authData.token)
        .then(() => {
          setRefresh(false);
        })
        .catch(err => onError(err));
    }
  }, [onError, authData.token, refresh, isTrashcan]);

  const handleReloadData = () => {
    console.log("refreshed");
    setRefresh(true);
  };

  const toggleTrashcan = () => {
    setIsTrashcan(isTrashcan => !isTrashcan);
    console.log("Cambió isTrashcan");
    console.log("isTrashcan", isTrashcan);
  };

  return (
    <div>
      <PageTitle
        title="sidebar.inventario.existencias"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.inventario"
          },
          {
            name: "sidebar.inventario.existencias"
          }
        ]}
      />

      <EmtecLoader visible={items?.length === 0} />

      {items?.length > 0 && (
        <ExistenciasTable
          data={items}
          //handleUpdate={handleUpdate}
          //handleInactive={handleInactive}
          //toggleTrashcan={toggleTrashcan}
          //isTrashcan={isTrashcan}
        />
      )}
    </div>
  );
};

export default connect(authData, { onError, onSuccess })(Existencias);
