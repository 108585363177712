import React, { useState, useMemo, useCallback, useEffect } from "react";
import { remove } from "lodash";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Pagination from "components/common/Pagination";
import { formatDate } from "util/utils";
import ReactTableWrapper from "components/reacttable/reacttbl.style";
import UsuarioEdit from "./UsuarioEdit";
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import FilterComponent from "components/reacttable/subcomponents/FilterComponent";
import HeaderComponent from "components/reacttable/subcomponents/HeaderComponent";
import DropdownFilterComponent from "components/reacttable/subcomponents/DropdownFilterComponent";

const UsuariosListaTable = props => {
  const [usersData, setUsersData] = useState(props.data);
  const [userData, setUserData] = useState({});
  const [modal, setModal] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);

  useEffect(() => {
    setUsersData(props.data);
  }, [props.data]);

  const deleteClick = useCallback(() => {
    props.handleInactive(userData);
    const array = usersData;
    remove(array, function(n) {
      return n.id === userData.id;
    });
    setUsersData([...array]);
    setWarningAlert(false);
  }, [usersData, props, userData]);

  const editClick = useCallback(data => {
    setUserData(data);
    setModal(true);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Usuario"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "Nombre de usuario",
        accessor: "username"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Nombre"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "Nombre completo",
        accessor: "full_name"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Email"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "Email",
        accessor: "email"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Rol"
            />
          );
        },
        Filter: DropdownFilterComponent,
        placeholder: "Rol",
        accessor: "role.display_name"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Fecha de alta"
            />
          );
        },
        Filter: FilterComponent,
        accessor: "created_at",
        placeholder: "Fecha de alta",
        Cell: props => <div> {formatDate(props.value)} </div>
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Acción"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class wide-cell">
              {/* {<button
                className="react-table-view-button"
                onClick={() => viewClick(tableInstance.row.original)}
              >
                <i className="fas fa-eye" />
              </button>} */}
              <button
                className="react-table-edit-button"
                onClick={() => editClick(tableInstance.row.original)}
              >
                <i className="fas fa-edit" />
              </button>
              <button
                className={`${
                  props.isTrashcan
                    ? "react-table-view-button"
                    : "react-table-delete-button"
                }`}
                onClick={() => {
                  setUserData(tableInstance.row.original);
                  setWarningAlert(true);
                }}
              >
                <i
                  className={`fas fa-${props.isTrashcan ? "recycle" : "trash"}`}
                />
              </button>
            </div>
          );
        }
      }
    ],
    [editClick, props.isTrashcan]
  );

  // const viewClick = (data) => {
  //   // Here you can view the data and make forward action for view data
  //   alert(JSON.stringify(data));
  // };

  const closeModal = () => {
    setUserData({});
    setModal(false);
  };

  const handleSubmit = data => {
    setUserData(data);
    props
      .handleUpdate(data)
      .then(() => closeModal())
      .catch(err => {
        throw err;
      });
  };

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      data: usersData,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <ReactTableWrapper {...props}>
        <div className="roe-card-style mt-15 mb-30">
          <div className="roe-card-header module-header">
            <div className="flex-1">
              <span className="hash"># </span> Usuarios
            </div>
            <div className="mr-10">
              <Button
                className="c-btn ma-5 c-primary"
                onClick={() => editClick({})}
              >
                <i className="fas fa-plus mr-10" />
                Nuevo
              </Button>
            </div>
            <div>
              <Button
                className="c-btn ma-5 c-warning"
                onClick={props.toggleTrashcan}
              >
                <i
                  className={`mr-10 fas fa-${
                    props.isTrashcan ? "recycle" : "trash"
                  }`}
                />
                Papelera
              </Button>
            </div>
          </div>
          <div className="table-container text-center overflow-auto">
            <table
              border={1}
              className="custom-react-table-theme-class"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(header => (
                      <th
                        {...header.getHeaderProps(
                          header.getSortByToggleProps()
                        )}
                      >
                        <div>{header.render("Header")}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(header => {
                      return (
                        <td
                          {...header.getHeaderProps(
                            header.getSortByToggleProps()
                          )}
                        >
                          <div>
                            {header.canFilter ? header.render("Filter") : null}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            onPageChange={gotoPage}
            pages={pageCount}
            page={pageIndex}
          />
        </div>
        <UsuarioEdit
          handleSubmit={handleSubmit}
          modalStatus={modal}
          userData={userData}
          closeModal={closeModal}
        />
        {!props.isTrashcan && (
          <SweetAlert
            warning
            showCancel
            show={warningAlert}
            confirmBtnText="Sí, deseo borrarlo"
            confirmBtnBsStyle="danger"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="default"
            title="¿Quieres borrar al usuario?"
            onConfirm={data => {
              deleteClick(data);
            }}
            onCancel={() => {
              setWarningAlert(false);
            }}
          >
            El usuario ya no podrá acceder al sistema
          </SweetAlert>
        )}
        {props.isTrashcan && (
          <SweetAlert
            warning
            showCancel
            show={warningAlert}
            confirmBtnText="Sí, deseo restaurarlo"
            confirmBtnBsStyle="info"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="default"
            title="¿Quieres restaurar al usuario?"
            onConfirm={data => {
              deleteClick(data);
            }}
            onCancel={() => {
              setWarningAlert(false);
            }}
          >
            El usuario recuperará el acceso al sistema
          </SweetAlert>
        )}
      </ReactTableWrapper>
    </>
  );
};

export default UsuariosListaTable;
