import React from "react";

const EntradasPreImportTableOk = ({ items }) => {
  return (
    <div className="roe-card-style">
      <div className="roe-card-header c-success mb-4">
        {Object.keys(items).length} Ítems listos para almacenar
      </div>
      <div className="roe-card-body">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Producto</th>
              <th scope="col">Cant.</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.description}</td>
                <td>{item.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EntradasPreImportTableOk;
