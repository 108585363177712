//import React from "react";

const modalDialogsActions = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
  CLEAR: "CLEAR",

  SUCCESS_CSS_CLASS: "c-primary",
  ERROR_CSS_CLASS: "c-danger",
  WARNING_CSS_CLASS: "c-warning",
  CLEAR_CSS_CLASS: "",

  SUCCESS_HEADER: "Muy bien",
  ERROR_HEADER: "Algo salió mal",
  WARNING_HEADER: "Aviso",
  CLEAR_HEADER: "CLEAR",

  onSuccess: data => {
    return {
      type: modalDialogsActions.SUCCESS,
      dialogType: modalDialogsActions.SUCCESS,
      dialogHeader: modalDialogsActions.SUCCESS_HEADER,
      dialogCssClass: modalDialogsActions.SUCCESS_CSS_CLASS,
      dialogContent: data.message,
      dialogDetails: null
    };
  },
  onError: data => {
    const content = `El servidor respondió ${data?.response?.status}: ${data.response?.statusText}. 
    Mensaje: ${data.message}`;
    return {
      type: modalDialogsActions.ERROR,
      dialogType: modalDialogsActions.ERROR,
      dialogHeader: modalDialogsActions.ERROR_HEADER,
      dialogCssClass: modalDialogsActions.ERROR_CSS_CLASS,
      dialogContent: content,
      dialogDetails: null
    };
  },
  onWarning: data => {
    return {
      type: modalDialogsActions.WARNING,
      dialogType: modalDialogsActions.WARNING,
      dialogHeader: modalDialogsActions.WARNING_HEADER,
      dialogCssClass: modalDialogsActions.WARNING_CSS_CLASS,
      dialogContent: data.message,
      dialogDetails: null
    };
  },
  clearMessage: () => {
    return {
      type: modalDialogsActions.CLEAR,
      dialogType: null,
      dialogHeader: null,
      dialogCssClass: null,
      dialogContent: null,
      dialogDetails: null
    };
  }
};

export default modalDialogsActions;
