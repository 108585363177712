import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap";
import { formatDate, toCurrency } from "util/utils";

const EntradasImportedDetail = ({ invoice }) => {
  const [modal, setModal] = useState(false);

  return (
    <div>
      <button
        className="react-table-view-button"
        onClick={() => setModal(!modal)}
      >
        <i className="fas fa-eye" />
      </button>
      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        className={"className"}
        size="lg"
      >
        <ModalHeader toggle={() => setModal(!modal)}>
          {invoice.document_type}
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>Fecha de timbrado:</strong>{" "}
            {formatDate(invoice.sender.created_at)}
          </p>
          <p>
            <strong>Fecha de importado:</strong>{" "}
            {formatDate(invoice.updated_at)}
          </p>
          <p>
            <strong>Total del CFDI:</strong>{" "}
            {toCurrency(invoice.breakdown.total)}
          </p>
          <hr />
          <p>
            <strong>Conceptos:</strong>
          </p>
          <Table>
            <thead>
              <tr>
                <th nowrap="nowrap">Código</th>
                <th>Descripción</th>
                <th nowrap="nowrap" align="center">
                  Unidades
                </th>
                <th nowrap="nowrap" align="right">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {invoice.concepts &&
                invoice.concepts.map(item => (
                  <tr key={`${invoice.id}-${item.id}`}>
                    <td nowrap="nowrap">{item.code}</td>
                    <td>{item.description}</td>
                    <td nowrap="nowrap" align="center">
                      {item.breakdown.quantity}
                    </td>
                    <td nowrap="nowrap" align="right">
                      {toCurrency(item.breakdown.total)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button className="c-secondary" onClick={() => setModal(!modal)}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EntradasImportedDetail;
